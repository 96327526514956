import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import Header from './Header'
import bigLogo from '../images/big_logo.svg'

const Subtitle = styled.p`
  font-style: italic;
  margin: 0;
  margin-left: 0.2rem;
`

export const ArticleHeader = ({ title, subtitle }) => (
  <Header>
    <a href="/">
      <img alt="logo" src={bigLogo} />
    </a>
    <section>
      <h1>{title}</h1>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </section>
  </Header>
)

export const Section = styled.section`
  font-size: 16px;
  background-color: white;
  text-align: left;
  > div {
    max-width: 50rem;
    margin-left: 14rem;
    margin-right: auto;
    border-left: 1px solid ${theme('colors.green')};
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;

    > footer {
      font-style: italic;
    }

    h2 {
      font-size: 1.5rem;
      margin-top: 1.5rem;
      margin-bottom: 0rem;
      &:first-child {
        margin-top: 0;
      }
    }

    h3 {
      font-size: 1.2rem;
      margin-top: 1rem;
      margin-bottom: 0.3rem;
    }

    p {
      margin-bottom: 1rem;
    }

    span.gatsby-resp-image-wrapper {
      margin: 3rem 0;
    }
    @media (max-width: 750px) {
      border: none;
      margin-left: 0;
      padding: 0 1rem;
    }
  }

  padding-bottom: 3rem;
  margin-bottom: 0;
`

export const Article = styled.article`
  ol ol {
    list-style-type: lower-alpha;
    margin: 0.3rem 0;
  }
`

export const Dinkus = styled.div`
  font-size: 1.5rem;
  letter-spacing: 1rem;
  text-align: center;
  margin: 2rem 0;
`
