import styled from 'styled-components'

const Header = styled.header`
  background-color: white;
  display: flex;
  flex-direction: row;
  padding-top: 2rem;
  padding-bottom: 2rem;
  a {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    width: 15rem;
    img {
      max-height: 5rem;
    }
  }
  section {
    max-width: 50rem;
    text-align: left;
    h1 {
      font-size: 3rem;
      margin-bottom: 0;
    }
  }
  @media (max-width: 750px) {
    a {
      display: none;
    }
    section {
      padding: 0 1rem;
      h1 {
        font-size: 1.8rem;
      }
    }
  }
`

export default Header
