import React from 'react'
import { graphql } from 'gatsby'
import Layout, { Footer } from '../components/Layout'
import { ArticleHeader, Section, Article } from '../components/Article'

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        date
        author
        image {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
    }
  }
`

const Post = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout
      title={frontmatter.title}
      description={frontmatter.description}
      author={frontmatter.author}
      type="document"
      image={frontmatter.image}
    >
      <Article>
        <ArticleHeader title={frontmatter.title} />
        <Section>
          <div>
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
          </div>
        </Section>
        <Footer></Footer>
      </Article>
    </Layout>
  )
}

export default Post
